<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("other.otherAction") }}</span
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card class="mx-auto" outlined>
          <v-card-title class="py-1">
            <h5
              style="max-width: 175px"
              :class="
                otherId
                  ? 'px-1 text-truncate font-italic primary--text font-weight-bold'
                  : 'primary--text text--darken-1'
              "
              class="font-weight-regular"
            >
              {{
                otherId
                  ? `${otherId}. ${originModel.description}...`
                  : $t("other.addOther")
              }}
            </h5>
            <v-spacer />
            <v-menu
              bottom
              v-if="otherId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!valid"
                >
                  <v-icon left>mdi-menu-down</v-icon>
                  {{ $t("other.options") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="accessRight.includes('create')"
                  dense
                  class="primary--text"
                  @click="copyOther"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text"
                        >mdi-content-copy</v-icon
                      >
                      {{ $t("other.copy") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  dense
                  class="primary--text"
                  @click="actDialog = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">mdi-pulse</v-icon>
                      {{ $t("other.activityLog") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense class="primary--text" @click="remarksDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-notebook-edit-outline
                      </v-icon>
                      {{$t('remarks.remarks')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              color="primary"
              small
              :loading="loading"
              @click="saveBookkeepingInvoice"
              :disabled="!valid"
            >
              {{ $t("other.save") }}
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="text-h5 pa-2">
            <FormSkeleton
              v-if="
                (otherId && Object.keys(otherById).length <= 0) ||
                (copiedOther && copyFormLoading)
              "
            />
            <v-form v-else ref="form" v-model="valid" lazy-validation>
              <Alert :alert.sync="alert" :message="message" :dynamicMsg="dynamicMsg" />
              <v-card class="mx-auto" flat>
                <v-card-text class="pa-0 px-2">
                  <v-row dense>
                    <v-col cols="6">
                      <v-text-field
                        class="asterisk"
                        hide-details="auto"
                        :label="$t('other.description')"
                        v-model.trim="model.description"
                        :rules="descriptionRequired"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        class="asterisk"
                        hide-details="auto"
                        :label="$t('other.accountNumber')"
                        v-model="model.bookkeeping_number"
                        :rules="accountNoRequired"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        hide-details="auto"
                        clearable
                        v-model="selectedBalanceSheet"
                        :items="balanceSheetValue"
                        item-text="description"
                        item-value="id"
                        return-object
                        :menu-props="{ bottom: true, offsetY: true }"
                        :label="$t('other.balanceSheetSection')"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        hide-details="auto"
                        :label="$t('other.grossProfit')"
                        v-model="model.gross_profit"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <ActivityLogTableDialog
        :actDialog.sync="actDialog"
        :docId.sync="otherId"
        docType="BookkeepingThree"
        docMode="sales"
        docItems="BookkeepingTwo"
      />
      <BookkeepingConfirmationDialog
        :dialog.sync="confirmationDialog"
        :bookkeepingConfirmation="confirmationMessage"
        types="Income"
        :isDuplicateMessage.sync="model.is_duplicate_message"
        @saveBookkeepingInvoice="saveBookkeepingInvoice"
      />
      <RemarksDialog :dialog.sync="remarksDialog" :docId="otherId" :docType="14" />
    </v-row>
  </v-container>
</template>

<script>
import Alert from "@/components/Alert";
import { mapGetters } from "vuex";
import FormSkeleton from "@/components/skeleton/FormSkeleton";
import { getAuxiliaryZtableValueByName, changedFormData } from "@/utils";
import BookkeepingConfirmationDialog from "@/components/BookkeepingConfirmationDialog";
import ActivityLogTableDialog from '@/components/activityLog/Dialogs/ActivityLogTableDialog.vue';
import RemarksDialog from "@/components/RemarksDialog";
import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "OtherAction",
  mixins: [validationMixin],
  data() {
    return {
      message: "",
      alert: false,
      dynamicMsg: false,
      loading: false,
      copyFormLoading: true,
      valid: false,
      model: {},
      originModel: {},
      actDialog: false,
      confirmationDialog: false,
      confirmationMessage: "",
      remarksDialog: false,
      balanceSheetValue: [],
      selectedBalanceSheet: null
    };
  },
  components: {
    Alert,
    FormSkeleton,
    BookkeepingConfirmationDialog,
    ActivityLogTableDialog,
    RemarksDialog,
  },
  computed: {
    otherId() {
      return this.$route.params.other_id;
    },
    copiedOther() {
      return this.$route.params.copiedModel;
    },
    ...mapGetters({
      accessRight: "accessRight",
      otherById: "other/otherById",
      otherQuery: "other/otherQuery",
    }),
  },
  mounted() {
    this.onMounteUpdated();
  },
  watch: {
    otherById() {
      if (Object.keys(this.otherById).length > 0) {
        this.model = Object.assign({}, this.model, this.otherById);
        this.originModel = Object.assign({}, this.originModel, this.model);
        this.selectedBalanceSheet = {id: this.model.balance_sheet_section_id};
      }
    },
  },
  methods: {
    async onMounteUpdated() {
      if (this.$route.params.copiedModel) {
        this.copyFormLoading = false;
        this.model = this.$route.params.copiedModel;
        delete this.model.balanceSheetSection;
        this.selectedBalanceSheet = { id: this.model.balance_sheet_section_id };
      } else if (this.$route.params.other_id) {
        await this.$store.dispatch(
          "other/GetOtherById",
          this.$route.params.other_id
        );
      }
      let tableNameValue = await getAuxiliaryZtableValueByName(9);
      if (Object.keys(tableNameValue).length > 0) {
        this.balanceSheetValue = tableNameValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
      }
    },
    callBackFunction() {
      this.$router.push({
        name: "Other",
        query: {
          ...this.otherQuery,
        },
      });
    },
    copyOther() {
      let copyModel = this.originModel;
      this.$router.push({
        name: "AddOther",
        params: {
          copiedModel: copyModel,
        },
      });
    },
    async saveBookkeepingInvoice() {
      this.dynamicMsg = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data;
        if (this.selectedBalanceSheet && Object.keys(this.selectedBalanceSheet).length > 0) {
          this.model.balance_sheet_section_id = this.selectedBalanceSheet.id;
        } else {
          this.model.balance_sheet_section_id = null
        }
        if (this.$route.params.other_id) {
          let saveData = changedFormData(this.model, this.originModel);
          data = await this.$store
            .dispatch("other/EditOther", {
              other_id: this.$route.params.other_id,
              other: saveData,
            })
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            if (
              data.data.type === "success" ||
              data.data.type === "confirmation"
            ) {
              if (data.data.type === "confirmation") {
                this.confirmationMessage = data.data.message;
                this.confirmationDialog = true;
              } else {
                this.dynamicMsg = true;
                this.message = data.data.message;
                this.alert = true;
              }
            } else {
              this.$refs.form.reset();
              await this.onMounteUpdated();
            }
            this.loading = false;
          }
        } else if (this.$route.params.copiedModel) {
          let copyModel = this.model;
        //   delete copyModel.gross_profit;
          delete copyModel.vat_credit_debit;
          delete copyModel.balance_cancelation;
          delete copyModel.reference_date_limit_from;
          delete copyModel.reference_date_limit_untill;
          delete copyModel.reference_two_date_limit_from;
          delete copyModel.reference_two_date_limit_untill;
          delete copyModel.bank_notes_date_limit_from;
          delete copyModel.bank_notes_date_limit_untill;
          delete copyModel.created_at;
          delete copyModel.updated_at;
          delete copyModel.id;
          copyModel.account_type = 3
          data = await this.$store
            .dispatch("other/SetOther", copyModel)
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            if (
              data.data.type === "success" ||
              data.data.type === "confirmation"
            ) {
              if (data.data.type === "confirmation") {
                this.confirmationMessage = data.data.message;
                this.confirmationDialog = true;
              } else {
                this.dynamicMsg = true;
                this.message = data.data.message;
                this.alert = true;
              }
              this.loading = false;
            } else {
              this.$refs.form.reset();
              this.loading = false;
              if (this.accessRight.includes("edit")) {
                await this.$router
                  .push("/other/editOther/" + data.data.id)
                  .catch(() => {});
              } else {
                this.callBackFunction();
              }
            }
          }
        } else {
          this.model.account_type = 3
          data = await this.$store
            .dispatch("other/SetOther", this.model)            
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
          if (data) {
            if (
              data.data.type === "success" ||
              data.data.type === "confirmation"
            ) {
              if (data.data.type === "confirmation") {
                this.confirmationMessage = data.data.message;
                this.confirmationDialog = true;
              } else {
                this.dynamicMsg = true;
                this.message = data.data.message;
                this.alert = true;
              }
              this.loading = false;
            } else {
              this.$refs.form.reset();
              this.loading = false;
              if (this.accessRight.includes("edit")) {
                await this.$router
                  .push("/other/editOther/" + data.data.id)
                  .catch(() => {});
              } else {
                this.callBackFunction();
              }
            }
          }
        }
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
  },
};
</script>
